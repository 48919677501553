"use server";

import { getCategories, getProductSearch } from "../apis/server_apis";
import { CategoryResult } from "../types/category";
import { Product } from "../types/search";

export type CatalogueRouteData = {
  categories: CategoryResult;
  popularBrands: Product[];
};

export const getCatalogueRouteData = async (): Promise<CatalogueRouteData> => {
  const categories$P = getCategories();
  const popularBrands$P = getProductSearch({ category: "POPULAR_BRANDS" });

  const resolved = await Promise.all([categories$P, popularBrands$P]);

  const categories = resolved[0];
  const popularBrands = resolved[1];

  const sortedPopularBrands = popularBrands.data[0].products.sort((a, b) => {
    return (
      (b.voucherProduct.discountPercentage || 0) -
      (a.voucherProduct.discountPercentage || 0)
    );
  });

  let routeData = {
    categories,
    popularBrands: sortedPopularBrands,
  };

  return routeData;
};
