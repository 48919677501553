import {
  createEffect,
  createMemo,
  createResource,
  createSignal,
  Index,
  Match,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import { placeholderCard } from "~/assets/assets";
import { getBrand } from "~/server/apis/client_apis";
import { VoucherProduct } from "~/server/types/brand";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";
import { useModal } from "../modal";
import { CartProduct } from "~/context/procurement";
import { toRupees } from "~/utils/number";

type DenominationDetailsModalProps = {
  brandId: string;
  discountPercentage: number;
  balance: number;
  addToCart: (newProduct: CartProduct) => void;
};

export const DenominationDetailsModal = ({
  brandId,
  discountPercentage,
  addToCart,
  balance,
}: DenominationDetailsModalProps) => {
  const [brandDetails] = createResource<VoucherProduct>(async () => {
    return getBrand(brandId);
  });
  const [showCustomDenomanination, setShowCustomDenomination] =
    createSignal(false);
  let customDenominationInput: HTMLInputElement | undefined;
  const inputMap = new Map<string, HTMLInputElement>();

  const customAmountDefaultDenominationsList = createMemo(() => {
    const denominations = [
      100, 250, 500, 750, 1000, 1500, 2000, 2500, 5000, 10000,
    ];
    return denominations.filter(
      (denomination) =>
        denomination >= (brandDetails()?.amountConditions?.minAmount ?? 0) &&
        denomination <= brandDetails()?.amountConditions?.maxAmount!
    );
  });

  const denominationList = createMemo(() => {
    if (brandDetails.state !== "ready") return [];
    return (
      brandDetails()?.amountConditions?.denominations ??
      customAmountDefaultDenominationsList()
    ).map((denomination: any) => {
      return {
        denomination,
        quantity: 0,
      };
    });
  });

  const [denominationsWithQuantity, setDenominationsWithQuantity] =
    createSignal<{ denomination: number; quantity: number }[]>([]);

  createEffect(() => {
    if (brandDetails.state === "ready") {
      setDenominationsWithQuantity(denominationList());
      setShowCustomDenomination(
        !(brandDetails()?.amountConditions?.denominations != undefined)
      );
    }
  });

  const handleQuantityChange = (denomination: number, newQuantity: number) => {
    setDenominationsWithQuantity((prev: any[]) =>
      prev.map((item: any) =>
        item.denomination === denomination
          ? {
              ...item,
              quantity: Math.max(0, newQuantity),
            }
          : item
      )
    );
  };

  const updateCustomQuantity = (amount: number, quantity: number) => {
    let currentMap = [...denominationsWithQuantity()];
    if (denominationList().find((item) => item.denomination === amount)) {
      return handleQuantityChange(amount, quantity);
    }
    if (quantity === 0) {
      currentMap = currentMap.filter((item) => item.denomination !== amount);
    } else {
      currentMap.push({
        denomination: amount,
        quantity: quantity,
      });
    }
    setDenominationsWithQuantity(currentMap);
  };

  const [customAmount, setCustomAmount] = createSignal(0);
  const [showCustomAmountError, setShowCustomAmountError] = createSignal(false);
  const [customAmountFieldActive, setCustomAmountFieldActive] =
    createSignal(false);

  const handleCustomAmountChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    const amountConditions = brandDetails()?.amountConditions;

    if (!isNaN(value)) {
      if (value != customAmount()) {
        if (
          value >= (amountConditions?.minAmount ?? 0) &&
          value <= amountConditions?.maxAmount!
        ) {
          updateCustomQuantity(customAmount(), 0);
          setCustomAmount(value);
          const selectedDenomination = denominationsWithQuantity().find(
            (item) => item.denomination == value
          );
          if (selectedDenomination) {
            if (selectedDenomination?.quantity === 0) {
              handleQuantityChange(customAmount(), 1);
            }
          } else {
            updateCustomQuantity(customAmount(), 1);
          }
          setShowCustomAmountError(false);
        } else {
          setShowCustomAmountError(true);
          updateCustomQuantity(customAmount(), 0);
          setCustomAmount(0);
        }
      }
    } else if (input.value === "") {
      updateCustomQuantity(customAmount(), 0);
      setCustomAmount(0);
      setShowCustomAmountError(false);
    }
  };

  const handleInputChange = (denomination: number, event: Event) => {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);

    if (!isNaN(value)) {
      handleQuantityChange(denomination, value);
    } else if (input.value === "") {
      handleQuantityChange(denomination, 0);
    }
  };

  const calculateTotalAmount = () => {
    return denominationsWithQuantity().reduce(
      (total: number, item: { denomination: number; quantity: number }) => {
        return total + item.denomination * item.quantity;
      },
      0
    );
  };

  const calculateDiscount = (totalAmount: number) => {
    return (totalAmount * discountPercentage) / 100;
  };

  const totalAmount = createMemo(() => calculateTotalAmount());
  const discountAmount = createMemo(() => calculateDiscount(totalAmount()));
  const finalAmount = createMemo(() => totalAmount() - discountAmount());

  const disableAddToCart = createMemo(() =>
    denominationsWithQuantity().every(
      (item: { quantity: number }) => item.quantity === 0
    )
  );

  const getDenominationQuantity = (denomination: number) => {
    return (
      denominationsWithQuantity().find(
        (item: { denomination: number; quantity: number }) =>
          item.denomination === denomination
      )?.quantity ?? 0
    );
  };

  const { openModal, closeModal } = useModal()!;

  const handleAddToCart = () => {
    const selectedDenominations = denominationsWithQuantity().filter(
      (item: { quantity: number }) => item.quantity > 0
    );

    selectedDenominations.forEach(
      (item: { denomination: number; quantity: number }) => {
        const totalAmountForDenomination = item.denomination * item.quantity;

        const product: CartProduct = {
          detail: {
            logoUrl: brandDetails()?.voucherProductMetadata?.iconImageUrl!,
            brandName: brandDetails()?.name!,
          },
          productId: brandId,
          denomination: item.denomination,
          amount: totalAmountForDenomination,
          quantity: item.quantity,
        };

        addToCart(product);
      }
    );

    closeModal();
  };

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <Switch>
        <Match when={brandDetails.state === "pending"}>
          <DottedLoader color="#999" />
        </Match>
        <Match when={brandDetails.state === "errored"}>
          <div>Error loading brand details: {brandDetails.error}</div>
        </Match>
        <Match when={brandDetails.state === "ready"}>
          <div class="flex w-full flex-col gap-5 rounded-[20px] pt-8">
            <div class="flex flex-col items-center justify-center gap-2">
              <HubbleImage
                src={brandDetails()?.voucherProductMetadata?.iconImageUrl!}
                class="inline-block h-[88px] w-[78px] rounded-xl"
                alt={`${brandDetails()?.name} logo image`}
                errorImage={placeholderCard}
              />
              <p class="text-h4 text-textDark">
                {brandDetails()?.name} gift card
              </p>
              {/* <button
                class="text-medium text-textNormal underline"
                onClick={() => {
                  closeModal();
                  openBrandDetailsModal();
                }}
              >
                Details
              </button> */}
            </div>
            <div class="noScrollbar m-auto flex flex-col gap-3 overflow-y-auto">
              {customDenominationField()}
              <Index each={denominationsWithQuantity()}>
                {(denomination, index) => {
                  // ignores adding custom amount entry as a denomination as already exists above
                  if (denomination().denomination == customAmount()) {
                    return null;
                  }
                  return (
                    <div class="flex w-[316px] items-center justify-between rounded-xl bg-gray-100 py-[8.5px] pl-3 pr-2">
                      <p class="text-mediumBold text-textDark">
                        ₹{denomination().denomination}
                      </p>
                      <div class="inline-block rounded-lg bg-basePrimaryDark">
                        <div class="flex items-center">
                          <button
                            type="button"
                            class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md  font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                            onClick={() => {
                              if (
                                inputMap.get(`${denomination().denomination}`)
                              ) {
                                inputMap
                                  .get(`${denomination().denomination}`)
                                  ?.focus();
                              }
                              handleQuantityChange(
                                denomination().denomination,
                                denomination().quantity - 1
                              );
                            }}
                            disabled={denomination().quantity === 0}
                          >
                            <PhosphorIcon
                              name="minus"
                              fontSize={12}
                              size="bold"
                              class="text-white"
                            />
                          </button>
                          <input
                            ref={(el) =>
                              inputMap.set(`${denomination().denomination}`, el)
                            }
                            class="[&::-webkit-outer-spin-button] :appearance-none w-6 border-0 bg-transparent p-0 text-center text-f12Bold text-white hover:underline  focus:underline focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none"
                            type="number"
                            value={denomination().quantity}
                            onInput={(e) =>
                              handleInputChange(denomination().denomination, e)
                            }
                          />
                          <button
                            type="button"
                            class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                            onClick={() => {
                              if (
                                inputMap.get(`${denomination().denomination}`)
                              ) {
                                inputMap
                                  .get(`${denomination().denomination}`)
                                  ?.focus();
                              }
                              handleQuantityChange(
                                denomination().denomination,
                                denomination().quantity + 1
                              );
                            }}
                          >
                            <PhosphorIcon
                              name="plus"
                              fontSize={12}
                              size="bold"
                              class="text-white"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Index>
            </div>
            <div class="sticky bottom-0 flex flex-col gap-3 border-t border-baseTertiaryDark bg-white px-2 pb-2 pt-3">
              <Switch>
                <Match when={!discountPercentage && !disableAddToCart()}>
                  <p class="text-center text-subtitleSmall text-textDark">
                    You pay {toRupees(totalAmount())}
                  </p>
                </Match>
                <Match when={disableAddToCart()}>
                  <p class="text-center text-medium text-textDark">
                    Please add gift card denominations to buy
                  </p>
                </Match>
                <Match when={!disableAddToCart()}>
                  <div class="flex justify-between">
                    <p class="text-center text-subtitleSmall text-textDark">
                      You pay{" "}
                      <span class="text-successDark">
                        {toRupees(finalAmount())}
                      </span>{" "}
                      <span class="text-medium text-textNormal line-through">
                        {toRupees(totalAmount())}
                      </span>
                    </p>
                    <p class="text-subtitleSmall text-successDark">
                      Instant {discountPercentage}% off
                    </p>
                  </div>
                </Match>
              </Switch>
              <button
                class="flex w-full items-center justify-center gap-1 rounded-lg bg-basePrimaryDark px-4 py-2.5 text-subtitleSmall text-white disabled:bg-baseSecondaryLight"
                disabled={disableAddToCart()}
                onClick={handleAddToCart}
              >
                <PhosphorIcon name="shopping-cart-simple" />
                Add to cart
              </button>
            </div>
          </div>
        </Match>
      </Switch>
    </Suspense>
  );

  function customDenominationField() {
    return (
      <div>
        <Show when={showCustomDenomanination()}>
          <div
            class={`flex w-[316px] cursor-pointer items-center justify-between rounded-xl border bg-gray-100 py-[6.5px] pl-3 pr-2 ${showCustomAmountError() ? " border-errorDark" : customAmountFieldActive() || customAmount() > 0 ? "border-basePrimaryDark" : "border-gray-100"}`}
            onClick={() => {
              if (customDenominationInput) {
                customDenominationInput.focus();
              }
            }}
          >
            <p class="flex h-7 items-center text-mediumBold text-textDark  ">
              ₹
              <input
                ref={customDenominationInput}
                type="number"
                placeholder="Enter amount"
                class="ml-1 border-0 bg-gray-100 [appearance:textfield] placeholder:text-medium placeholder:text-textNormal [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                onInput={(e) => handleCustomAmountChange(e)}
                onFocusIn={() => setCustomAmountFieldActive(true)}
                onFocusOut={() => setCustomAmountFieldActive(false)}
              />
            </p>
            <Show when={customAmount() > 0 && !showCustomAmountError()}>
              <div class="inline-block rounded-lg bg-basePrimaryDark">
                <div class="flex items-center">
                  <button
                    type="button"
                    class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md  font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                    onClick={() =>
                      handleQuantityChange(
                        customAmount(),
                        getDenominationQuantity(customAmount()) - 1
                      )
                    }
                    disabled={getDenominationQuantity(customAmount()) < 1}
                  >
                    <PhosphorIcon
                      name="minus"
                      fontSize={12}
                      size="bold"
                      class="text-white"
                    />
                  </button>
                  <input
                    onClick={(e) => e.stopPropagation()}
                    class="[&::-webkit-outer-spin-button] :appearance-none w-6 border-0 bg-transparent p-0 text-center text-f12Bold text-white hover:underline focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none"
                    type="number"
                    value={getDenominationQuantity(customAmount())}
                    onInput={(e) => handleInputChange(customAmount(), e)}
                  />
                  <button
                    type="button"
                    class="text-sm shadow-sm inline-flex size-7 items-center justify-center gap-x-2 rounded-md font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                    onClick={() =>
                      handleQuantityChange(
                        customAmount(),
                        getDenominationQuantity(customAmount()) + 1
                      )
                    }
                  >
                    <PhosphorIcon
                      name="plus"
                      fontSize={12}
                      size="bold"
                      class="text-white"
                    />
                  </button>
                </div>
              </div>
            </Show>
          </div>
        </Show>
        <Show when={showCustomAmountError()}>
          <div class="mt-1 text-f12 text-errorDark">
            Please enter an amount between ₹
            {brandDetails()?.amountConditions.minAmount} to ₹
            {brandDetails()?.amountConditions.maxAmount}
          </div>
        </Show>
      </div>
    );
  }
};
